/*root styles start*/
:root{
    /*color styles*/
    --pxft-blue:#0077D3;
    --pxft-yellow:#fcb402;
    --pxft-primary:#fcb402;
    --pxft-lightgrey:#F8F8F8;
    --pxft-mildgrey:#e0e0e0;
    --pxft-midgrey:#989898;
    --pxft-darkgrey:#424242;
    --pxft-transparent:rgba(0,0,0,0);
    /*font size*/
    --pxft-font-s:12px;
    --pxft-font-normal:14px;
    --pxft-font-m:16px;
    --pxft-font-l:20px;
    --pxft-font-xl:24px;
    --pxft-font-xxl:28px;
    --pxft-font-xxxl:38px;
    --pxft-font-title:48px;
    /*font weight*/
    --pxft-font-bold:600;
    --pxft-font-exbold:800;
    /*letter spacing*/
    --pxft-font-space-l:-0.24px;
    --pxft-font-space-m:-0.06px;
    --pxft-font-space-s:-0.05px;
    /*page max-width*/
    --pxft-max-width: 1200px;
    
}
/*root styles end*/

/*Glogal styles start*/
* {
  box-sizing: 'border-box';
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, 'Chakra Petch',BlinkMacSystemFont,  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color:#fff;
  font-weight: 300;
  background-color: #000;
}
a {
  text-decoration: none;
  color:var(--pxft-mildgrey);
}
a:hover {
  color:var(--pxft-primary);
}

/*Glogal styles end*/

/*typography start*/
h1 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-xxxl);
    letter-spacing: var(--pxft-font-space-l)
}
h2 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-xxl);
    letter-spacing: var(--pxft-font-space-l)
}
h3 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-xl);
    letter-spacing: var(--pxft-font-space-m)
}
h4 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-l);
    letter-spacing:var(--pxft-font-space-m)
}
h5 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-m);
    letter-spacing: var(--pxft-font-space-s)
} 
h6 {
    font-weight: var(--pxft-font-bold);
    font-size:var(--pxft-font-normal);
    letter-spacing: var(--pxft-font-space-s)
}
p {
    font-size:var(--pxft-font-normal);
}
.pxft-text-small {
    font-size:var(--pxft-font-s);
    letter-spacing: var(--pxft-font-space-s)
}
/*typography end*/



/*others start*/
.column-container{
  padding:0 30px;
}
.basic-header-container{
  position: relative;
  background-color: var(--pxft-lightgrey);
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 130px 0;
}
.basic-header-container .header-content{
  z-index: 2;
}
.basic-header-container img{
  width: 100%;
  max-width: 1000px;
  margin-right: 0;
  margin-left: auto;
  margin-right: auto;
}
.policy-container{
  background-color: rgba(20,20,20,.8);
  color:white;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position:sticky;
  bottom:0;
  z-index: 999;
}
.policy-container a{
  color: white;
}
.policy-container a:hover{
  color: var(--pxft-primary);
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
  .mobile-reverse{
    flex-direction: column-reverse !important;
  }
  .basic-header-container{
    padding: 80px 0; } 
}

@media only screen and (max-width: 599px) {
  .mobile-reverse{
    flex-direction: column-reverse !important;
  }
  .basic-header-container{
    height: auto;
    padding: 60px 0px; } 
}


/*otherss end*/

/*align sets*/
.center-align{
    position: relative;
    display:flex;
    justify-content: center;
    align-items:center;
}
.left-align{
  margin-left: 0;
  margin-right: auto;
}
.right-align{
  margin-left: auto;
  margin-right: 0;
}
/*align sets end*/

.contentPic{
  width:90%;
  padding:40px 0px;
}
.contentVideo{
  width:100%;
  padding:40px 20px;
}
.row-list{
  padding: 5px 0px 5px 15px
}
.row-list::before {
  content: '';
}
.observer{
  position: absolute;
  top:50%;
}
.long-observer{
  position: absolute;
  top:20%;
}
.scroll-top{
  position: fixed;
  right: 50px;
  bottom: -60px;
  z-index: 999;
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  text-align: center;
  font-size: 30px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: var(--pxft-primary);
  color: #fff;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden; 
}
.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px; }


@media only screen and (max-width: 599px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    right:30px;
    line-height: 25px;
    font-size: 25px; } 
}

/*info page starts*/
.info-container{
  position: relative;
  padding: 100px 40px;
}
/*info page ends*/

/*title component*/
.title-container{
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
.title-container h1{
  color:black;
  width:100%;
  text-align:center;
  padding:50px 0 20px 0;
}

/*brandbook starts*/
.brandbook-container .brandbook-content{
  padding: 30px 0;
}
.brandbook-container .color-box{
  width: 40%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight: 500;
  margin:20px;
}
.fine-print-container{
  padding:20px 10px 60px;
  width: 90%;
}
.fine-print-container .content{
  width: 90%;
}
.fine-print-container h3{
  color:white;
  background-color: var(--pxft-blue);
  padding: 10px;
  margin: 20px;
  width: 30%;
}
.fine-print-container p{
  padding: 5px 15px 5px;
}
/*brandbook ends*/

/*contact animation starts*/
.contact-info-container{
  padding: 250px 0;
  overflow: hidden;
}
.icon-up-down-animation {
  position: relative;
  z-index: 9; }
  .icon-up-down-animation .shape {
    position: absolute;
    display: inline-block;
    width: 96px;
    height: 96px;
    z-index: -1; }
    .icon-up-down-animation .shape img {
      -webkit-box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.1);
      box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.1);
      border-radius: 35px; }
    .icon-up-down-animation .shape-1 {
      top: -170px;
      left: 300px;
      -webkit-animation: up-down infinite 4s ease-in-out;
      animation: up-down infinite 4s ease-in-out; }
    .icon-up-down-animation .shape-2 {
      top: -150px;
      right: 400px;
      -webkit-animation: up-down infinite 5s ease-in-out;
      animation: up-down infinite 5s ease-in-out; }
    .icon-up-down-animation .shape-3 {
      top: -55px;
      left: 15px;
      -webkit-animation: up-down infinite 6s ease-in-out;
      animation: up-down infinite 6s ease-in-out; }
    .icon-up-down-animation .shape-4 {
      top: -55px;
      right: 15px;
      width: 130px;
      height: 130px;
      -webkit-animation: up-down infinite 4s ease-in-out;
      animation: up-down infinite 4s ease-in-out; }
      .icon-up-down-animation .shape-4 img {
        border-radius: 45px; }
    .icon-up-down-animation .shape-5 {
      bottom: -80px;
      left: 80px;
      width: 130px;
      height: 130px;
      -webkit-animation: up-down infinite 5s ease-in-out;
      animation: up-down infinite 5s ease-in-out; }
      .icon-up-down-animation .shape-5 img {
        border-radius: 45px; }
    .icon-up-down-animation .shape-6 {
      bottom: -30px;
      right: 300px;
      -webkit-animation: up-down infinite 6s ease-in-out;
      animation: up-down infinite 6s ease-in-out; }
    .icon-up-down-animation .shape-7 {
      bottom: -40px;
      left: 300px;
      -webkit-animation: up-down infinite 4s ease-in-out;
      animation: up-down infinite 4s ease-in-out; }
    .icon-up-down-animation .shape-8 {
      bottom: -155px;
      right: 80px;
      -webkit-animation: up-down infinite 5s ease-in-out;
      animation: up-down infinite 5s ease-in-out; }
    .icon-up-down-animation .shape-9 {
      bottom: 0;
      right: 15px;
      -webkit-animation: up-down infinite 6s ease-in-out;
      animation: up-down infinite 6s ease-in-out; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .icon-up-down-animation .shape-1 {
      top: -170px;
      left: 300px; }
    .icon-up-down-animation .shape-2 {
      top: -150px;
      right: 150px; }
    .icon-up-down-animation .shape-3 {
      top: -55px;
      left: 15px; }
    .icon-up-down-animation .shape-4 {
      top: -55px;
      right: 0px;
      width: 100px;
      height: 100px; }
      .icon-up-down-animation .shape-4 img {
        border-radius: 35px; }
    .icon-up-down-animation .shape-5 {
      bottom: -80px;
      left: 20px;
      width: 100px;
      height: 100px; }
      .icon-up-down-animation .shape-5 img {
        border-radius: 35px; }
    .icon-up-down-animation .shape-6 {
      bottom: -30px;
      right: 230px; }
    .icon-up-down-animation .shape-7 {
      bottom: -40px;
      left: 180px; }
    .icon-up-down-animation .shape-8 {
      bottom: -125px;
      right: 60px; }
    .icon-up-down-animation .shape-9 {
      bottom: 0;
      right: 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .icon-up-down-animation .shape {
      width: 60px;
      height: 60px; }
      .icon-up-down-animation .shape img {
        border-radius: 20px; }
      .icon-up-down-animation .shape-1 {
        top: -115px;
        left: 150px; }
      .icon-up-down-animation .shape-2 {
        top: -115px;
        right: 150px; }
      .icon-up-down-animation .shape-3 {
        top: -80px;
        left: 15px; }
      .icon-up-down-animation .shape-4 {
        top: -100px;
        right: 0px;
        width: 90px;
        height: 90px; }
        .icon-up-down-animation .shape-4 img {
          border-radius: 35px; }
      .icon-up-down-animation .shape-5 {
        bottom: 0;
        left: 10px;
        width: 90px;
        height: 90px; }
        .icon-up-down-animation .shape-5 img {
          border-radius: 35px; }
      .icon-up-down-animation .shape-6 {
        bottom: 30px;
        right: 150px; }
      .icon-up-down-animation .shape-7 {
        bottom: 40px;
        left: 160px; }
      .icon-up-down-animation .shape-8 {
        bottom: -70px;
        right: 70px; }
      .icon-up-down-animation .shape-9 {
        bottom: 10px;
        right: 15px; } }
  @media only screen and (max-width: 767px) {
    .icon-up-down-animation .shape {
      width: 50px;
      height: 50px; }
      .icon-up-down-animation .shape img {
        border-radius: 15px; }
      .icon-up-down-animation .shape-1 {
        top: -115px;
        left: 150px; }
      .icon-up-down-animation .shape-2 {
        top: -115px;
        right: 150px; }
      .icon-up-down-animation .shape-3 {
        top: -80px;
        left: 15px; }
      .icon-up-down-animation .shape-4 {
        top: -100px;
        right: 0px;
        width: 60px;
        height: 60px; }
        .icon-up-down-animation .shape-4 img {
          border-radius: 20px; }
      .icon-up-down-animation .shape-5 {
        bottom: 0;
        left: 10px;
        width: 60px;
        height: 60px; }
        .icon-up-down-animation .shape-5 img {
          border-radius: 20px; }
      .icon-up-down-animation .shape-6 {
        bottom: 0px;
        right: 120px; }
      .icon-up-down-animation .shape-7 {
        bottom: 10px;
        left: 100px; }
      .icon-up-down-animation .shape-8 {
        bottom: -70px;
        right: 45px; }
      .icon-up-down-animation .shape-9 {
        bottom: 10px;
        right: 15px; } }
  @media only screen and (max-width: 575px) {
    .icon-up-down-animation .shape-6 {
      bottom: 0px;
      right: 90px; } }
  @media only screen and (max-width: 767px) {
    .icon-up-down-animation .shape-1 {
      top: -110px;
      left: 90px; }
    .icon-up-down-animation .shape-2 {
      top: -110px;
      right: 90px; }
    .icon-up-down-animation .shape-3 {
      top: -80px;
      left: 15px; }
    .icon-up-down-animation .shape-4 {
      top: -100px;
      right: 10px; }
    .icon-up-down-animation .shape-5 {
      bottom: 0;
      left: 10px;
      width: 60px;
      height: 60px; }
      .icon-up-down-animation .shape-5 img {
        border-radius: 20px; }
    .icon-up-down-animation .shape-6 {
      display: none; }
    .icon-up-down-animation .shape-7 {
      display: none; }
    .icon-up-down-animation .shape-8 {
      bottom: -70px;
      right: 45px; }
    .icon-up-down-animation .shape-9 {
      bottom: 10px;
      right: 15px; } }

/* Up Down Animation */
.up-down {
  -webkit-animation: up-down infinite 2s ease-in-out;
  animation: up-down infinite 2s ease-in-out; }

@-webkit-keyframes up-down {
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); } }

@keyframes up-down {
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); } }
/*contact animation ends*/

/*testimonial component*/
.testimonial-container{
  padding: 0 30px;
}
.testimonial-slider .swiper-slide.swiper-slide-prev {
  opacity: 0.5; }

.testimonial-slider .swiper-slide.swiper-slide-next {
  opacity: 0.5; }

.static-testimonial {
  padding: 50px 40px 55px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(51, 51, 51, 0.1);
  box-shadow: 0px 0px 40px 0px rgba(51, 51, 51, 0.1);
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .static-testimonial {
      padding: 30px 25px 35px; } }
  .static-testimonial .testimonial-image {
    margin-bottom: 25px; }
    .static-testimonial .testimonial-image img {
      max-width: 100px;
      border-radius: 50%;
      border: var(--pxft-mildgrey) solid 2px;
     }
  .static-testimonial .testimonial-content {
    margin-bottom: 25px; }
    .static-testimonial .testimonial-content p {
      font-size: 18px;
      line-height: 27px;
      color: var(--pxft-midgrey);
      margin-bottom: 0; }
  .static-testimonial .author-info {
    display: inline-block; }
    .static-testimonial .author-info .cite .name {
      display: block;
      font-size: 17px;
      line-height: 1.3;
      margin-bottom: 0;
      font-weight: 400;
      text-transform: uppercase;
      color: var(--pxft-primary); }
/**/

/*footer starts*/
    .pxft-footer-container {
        padding: 100px 0px 50px;
        overflow: hidden; 
        background: black;
    }

    .footer-content{
        padding: 10px 0 50px 100px;
    }
    .footer-logo img{
        width: 100%;
        max-width: 200px;
    }
    @media only screen and (min-width: 599px) and (max-width: 899px) {
        .pxft-footer-container {
          padding: 80px 0px 50px; } }

    @media only screen and (max-width: 599px) {
        .pxft-footer-container {
          padding: 60px 0px  50px; } 
        .footer-content{
            padding: 10px 0 50px 50px
        }
    }


    .copyright-container {
        display:flex;
        justify-content: center;
        align-items:center;
        width: 100%;
        border-top: 1px solid var(--pxft-midgrey);
        flex-direction: column;
    }
    .media-icons a{
       padding:20px 10px 0;
    }
  .copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    color: var(--pxft-lightgrey);
    margin-bottom: 0; 
    }

    .footer-logo {
    margin-bottom: 30px;
    }
      
    .footer-widget-title {
        font-size: 18px;
        color: white;
        margin-bottom: 17px; 
    }
      
     .footer-widget-content {
        width: 80%;
        font-size: 18px;
        color: var(--pxft-mildgrey); 
        padding-bottom: 50px;}

        .footer-widget-content p {
          margin-bottom: 5px;
          line-height: 1.5;
          color: var(--pxft-mildgrey); }

          .footer-widget-content p:last-child {
            margin-bottom: 0; }

        .footer-widget-content ul {
          margin-right: -20px;
          margin-bottom: -5px;
          padding-left: 0;
          list-style: none; }
          .footer-widget-content ul li {
            margin-bottom: 5px;
            padding-right: 20px; }
            .footer-widget-content ul li a {
              font-size: 16px;
              line-height: 30px;
              color:var(--pxft-mildgrey) }
              @media only screen and (min-width: 899px) and (max-width: 1199px) {
                .footer-widget-content ul li a {
                  font-size: 13px; } }
              @media only screen and (max-width: 599px) {
                .footer-widget-content ul li a {
                  font-size: 13px; } }
      
      .ft-badge {
        background-color: var(--pxft-primary);
        display: inline-block;
        border-radius: 2px;
        padding: 4px 8px 3px;
        color: white;
        font-size: 10px;
        line-height: 1;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .5px;
        margin: 0 0 0 7px; }
      
      .footer-social-inline {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 40px;
        margin-right: -10px;
        margin-bottom: -10px; }
        .footer-social-inline a {
          margin-right: 10px;
          margin-bottom: 10px;
          color: var(--pxft-midgrey); }
          .footer-social-inline a i {
            font-size: 24px;
            line-height: 1;
            display: block; }
          .footer-social-inline a:hover {
            color: var(--pxft-primary); }

/*footer ends*/

/*grecaptcha-badge*/
.grecaptcha-badge{
  z-index: 20;
}
/*grecaptcha-badge*/
