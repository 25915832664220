.nav {
    position:absolute;
    top:0;
    background-color: rgba(255,255,255,0);
    border-bottom: 1px solid var(--pxft-darkgrey);
    width: 100%;
    height: 80px;
    padding: 0 20px;
    z-index: 50;
}
.nav-navi-contents{
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0 40px;
}
.logoImg{
    bottom:10px;
    width: 130px;
}

.site-main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    
    .site-main-menu > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0;
      padding-left: 0;
      list-style: none; }

      .site-main-menu > ul > li {
        position: relative; 
    }
        .site-main-menu > ul > li > a {
          line-height: 1.375;
          display: block;
          padding: 28px 15px;
          color: var(--pxft-mildgrey); 
          border-bottom: 2px solid var(--pxft-transparent); 
        }

        .site-main-menu > ul > li > a .menu-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; 
            font-weight: 500;
        }

        .site-main-menu > ul > li:hover > a {
          color: white;
          border-bottom: 2px solid var(--pxft-primary); 
        }
        .site-main-menu > ul > li:hover > .sub-menu {
          visibility: visible;
          margin-top: 10px;
          opacity: 1; }

    .site-main-menu .sub-menu {
      position: absolute;
      z-index: 50;
      top: 100%;
      left: 0;
      visibility: hidden;
      width: 240px;
      margin-top: 20px;
      margin-bottom: 0;
      padding: 0px 0;
      list-style: none;
      -webkit-transition: all 0.4s ease 0.2s;
      -o-transition: all 0.4s ease 0.2s;
      transition: all 0.4s ease 0.2s;
      opacity: 0;
      border-radius: 5px;
      background-color: #000;
      -webkit-box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
      box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05); }

      .site-main-menu .sub-menu::before {
        content: "";
        position: absolute;
        top: -10px;
        left: 40px;
        text-align: center;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #fff transparent; }

      .site-main-menu .sub-menu li {
        position: relative; }
        .site-main-menu .sub-menu li a{
          line-height: 1.375;
          display: block;
          padding: 16px 30px;
          white-space: nowrap;
          color: var(--pxft-darkgray);
         }

        .site-main-menu .sub-menu li a .menu-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; }

        .site-main-menu .sub-menu li:hover > a, .site-main-menu .sub-menu li.active > a{
            background-color: var(--pxft-lightgrey);
            color:var(--pxft-primary);
        }
        .site-main-menu .sub-menu li:hover > .sub-menu {
          visibility: visible;
          margin-top: 0px;
          opacity: 1; }

        .site-main-menu .sub-menu .sub-menu {
        top: -18px;
        left: 100%; }
        .site-main-menu .sub-menu .sub-menu::before {
          display: none; }

.is-sticky{
    position:fixed;
    background-color: rgba(0,0,0,0.75);
    border-bottom: 1px solid var(--pxft-midgrey);
    filter: drop-shadow(0px 1px 5px rgba(0,0,0,0.12));
    top:0;
    transition: 500ms;
    animation: slideDown .95s ease forwards;
}
@keyframes slideDown {
    0% {
    transform: translateY(-100%);
}                                                                               
    100% {
        transform: translateY(0);
    }
}

/*mobile menu*/
.nav-mobile-menu{

}
.nav-mobile-menu .settingIcon{
    border: 1px solid var(--pxft-mildgrey);
    border-radius: 3px;
    padding:5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .nav-navi-contents{
        padding: 0 20px;
    }
}
@media only screen and (max-width: 599px) {
    .nav-navi-contents{
        padding: 0 10px;
    }
}