/*header starts*/
.home-header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.home-header-container .home-video video{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
}
.home-background-img img{
    position:absolute;
    top:0;
    width: 100%;
}
.home-title-container{
    position: relative;
    height: 70vh;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.home-title-container .home-title{
    position: absolute;
    width: 160%;
    height: 70%;
    display: flex;
    flex-direction: column;
}
.home-title .words-container{
    padding: 70px 0 90px 0;
    z-index: 1;
}

.home-title .title{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0 0 10px 0;
}
.home-title .subtitle{
    color:var(--pxft-primary);
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:auto;
    line-height: 1;
    letter-spacing: 0.8px;
    font-size: 28px;
    font-weight: 500;
}
.home-title .subtitle::before{
    content: '';
    border-top: 2px solid var(--pxft-primary);
    width: 40px;
    margin: 0 1rem;
    padding: 0 5px;
}
.home-title .subtitle::after{
    content: '';
    border-top: 2px solid var(--pxft-primary);
    width: 40px;
    margin: 0 1rem;
    padding: 0 5px;
}

.home-title h1{
    color: white;
    font-size: 70px;
    font-weight: var(--pxft-font-exbold);
    text-align: center;
    line-height: 1.3;
    margin:auto;
}

.home-title .btn-container {
    display: flex;
    justify-content: center;
    z-index: 1;
}

.home-title .btn-container button{
    width:180px;
    height: 50px;
}
.home-title .btn-container p{
    margin:0;
}

@media only screen and (min-width: 899px) and (max-width: 1399px) {
    .home-header-container .home-video video{
        width: auto;
        height: 100%;
    }
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .home-header-container .home-video video{
        width: auto;
        height: 100%;
    }
    .home-title .words-container{
        padding: 20px 0 60px 0;
    }
    .home-title .title{
        width: 70%;
    }
    .home-title h1{
        font-size: var(--pxft-font-xxxl);
    }
}
@media only screen and (max-width: 599px) {
    .home-header-container .home-video video{
        width: auto;
        height: 100%;
    }
    .home-title .words-container{
        padding: 20px 0 60px 0;
    }
    .home-title h1{
        font-size: var(--pxft-font-xxxl);
    }
    .home-title .subtitle{
        font-size: var(--pxft-font-l);
    }
    .home-title .rectangle{
        width: 100vw;
        max-width: 450px;
    }
}
/*header ends*/

/*homeArticle starts*/
.home-article-container {
    padding: 0 0 50px 0;
}

.home-article-container .ad-container{
    padding: 50px 0;
}
.home-article-container .inventory-container{
    padding: 50px 0;
}

.home-article-container .home-background-column{
    height:800px;
    display: flex;
}
/**homeArticle ends*/

/*title component*/
.title-container{
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  .title-container h1{
    color:white;
    width:100%;
    text-align:center;
    padding:50px 0 20px 0;
  }

.newsletter-form-error{
    color: var(--pxft-primary);
}